import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from '../components/SectionLayout';
import Button from '../components/ui/Button';
import Label from '../components/ui/Label';

// Xアイコンのコンポーネント（SVGを使用）
const XIcon = ({ className }) => (
  <svg className={className} fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932L18.901 1.153ZM17.61 20.644h2.039L6.486 3.24H4.298L17.61 20.644Z" />
  </svg>
);

// 各サービスカードのコンポーネント
const ServiceCard = ({ titleId, descriptionId, xLink, siteLink, imageSrc, imageAltId, index, label, isLast }) => {
  const intl = useIntl();
  return (
    <div className={`flex flex-col lg:flex-row items-start justify-between pt-12 ${isLast ? '' : 'pb-12 border-b border-gray-200'} animate-fadeInUp`} style={{animationDelay: `${index * 0.2}s`}}>
      {/* サービス説明部分 */}
      <div className="w-full lg:w-1/2 lg:pr-8">
        <div className="flex flex-col xs:flex-row xs:items-center mb-4">
          {label && (
            <div className="mb-2 xs:mb-0 xs:order-2">
              <Label type={label.type} isVisible={label.isVisible} />
            </div>
          )}
          <h3 className="text-2xl font-bold text-text-default font-serif w-full xs:w-auto xs:mr-2 xs:order-1">
            {intl.formatMessage({ id: titleId })}
          </h3>
        </div>
        <p className="text-text-default leading-relaxed mb-6 font-body">
          {intl.formatMessage({ id: descriptionId })}
        </p>
        {/* ボタン部分 */}
        <div className="flex flex-col sm:flex-row gap-4">
          {siteLink && (
            <Button href={siteLink} variant="default" size="lg" className="w-full sm:w-auto py-2.5">
              {intl.formatMessage({ id: 'service.button.site' })}
            </Button>
          )}
          {xLink && (
            <Button href={xLink} variant="xlink" size="lg" className="w-full sm:w-auto py-2.5">
              <XIcon className="w-5 h-5 mr-2" />
              {intl.formatMessage({ id: 'service.button.x' })}
            </Button>
          )}
        </div>
      </div>
      {/* サービス画像部分 */}
      <div className="w-full lg:w-1/2 mt-6 lg:mt-0">
        <div className="aspect-[3/2] w-full rounded-lg overflow-hidden">
          {/* 画像コンテナ：オーバーフローを隠し、ホバーエフェクトを制御 */}
          <div className="w-full h-full overflow-hidden">
            <img
              src={imageSrc}
              alt={intl.formatMessage({ id: imageAltId })}
              className="w-full h-full object-cover sm:transition-transform sm:duration-500 sm:ease-in-out sm:hover:scale-105"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// メインのServiceコンポーネント
export default function Service() {
  const intl = useIntl();

  // サービス情報の配列
  const services = [
    {
      titleId: 'service.card1.title',
      descriptionId: 'service.card1.description',
      siteLink: 'https://japan-toreca.com/',
      xLink: 'https://twitter.com/jpn_tcg_center',
      imageSrc: `${process.env.PUBLIC_URL}/assets/images/img_service_jp_app.png`,
      imageAltId: 'service.card1.imageAlt',
    },
    {
      titleId: 'service.card2.title',
      descriptionId: 'service.card2.description',
      siteLink: 'https://shop.japan-toreca.com/',
      imageSrc: `${process.env.PUBLIC_URL}/assets/images/img_service_single_shop.png`,
      imageAltId: 'service.card2.imageAlt',
    },
    {
      titleId: 'service.card3.title',
      descriptionId: 'service.card3.description',
      xLink: 'https://x.com/jtc_kaitori',
      imageSrc: `${process.env.PUBLIC_URL}/assets/images/img_service_akiba_store.png`,
      imageAltId: 'service.card3.imageAlt',
    },
    {
      titleId: 'service.card4.title',
      descriptionId: 'service.card4.description',
      siteLink: 'https://us.japan-toreca.com/',
      xLink: 'https://x.com/jtc_world',
      imageSrc: `${process.env.PUBLIC_URL}/assets/images/img_service_gl_web.png`,
      imageAltId: 'service.card4.imageAlt',
      label: { type: 'beta', isVisible: true },
    },
  ];

  return (
    <SectionLayout id="service" accent="primary">
      <h2 className="section-title font-heading animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'service.title' })}
      </h2>
      <p className="section-subtitle font-body animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'service.subtitle' })}
      </p>
      {/* サービスカードのマッピング */}
      <div>
        {services.map((service, index) => (
          <ServiceCard 
            key={index} 
            {...service} 
            index={index}
            isLast={index === services.length - 1}
          />
        ))}
      </div>
    </SectionLayout>
  );
}