import React from 'react';

const Label = ({ type, isVisible, className = '' }) => {
  if (!isVisible) return null;

  const baseClasses = 'inline-flex items-center px-2 py-0.5 text-xs font-bold rounded-full text-white md:ml-2 font-open-sans';
  
  const typeClasses = {
    new: 'bg-hot-pink',
    beta: 'bg-blue-500',
  };

  const classes = `${baseClasses} ${typeClasses[type]} ${className}`;

  return (
    <span className={classes}>
      {type.toUpperCase()}
    </span>
  );
};

export default Label;