import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';

const VIDEOS = [
  '/assets/videos/video1.WebM',
  '/assets/videos/video2.WebM',
  '/assets/videos/video3.WebM'
];

const IMAGES = [
  '/assets/images/hero1.jpg',
  '/assets/images/hero2.jpg',
  '/assets/images/hero3.jpg'
];

const TRANSITION_DURATION = 1000; // ミリ秒
const CONTENT_DURATION = 8000; // ミリ秒
const PRELOAD_TIME = 1000; // プリロード時間（ミリ秒）
const MAX_CONTENT_DURATION = 8000; // 最大表示時間（ミリ秒）
const MIN_CONTENT_DURATION = 5000; // 最小表示時間（ミリ秒）

export default function Hero() {
  const intl = useIntl();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const videoRefs = useRef(VIDEOS.map(() => React.createRef()));
  const [videoDurations, setVideoDurations] = useState(VIDEOS.map(() => 0));
  const timeoutRef = useRef(null);

  const [ref] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 640);
      setIsDesktop(window.innerWidth >= 1024);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const switchContent = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (isMobile ? IMAGES.length : VIDEOS.length));
  }, [isMobile]);

  const startTransition = useCallback(() => {
    if (!isMobile) {
      setIsTransitioning(true);
      const newNextIndex = (currentIndex + 1) % VIDEOS.length;
      setNextIndex(newNextIndex);

      const nextVideo = videoRefs.current[newNextIndex].current;
      if (nextVideo) {
        nextVideo.currentTime = 0;
        nextVideo.play().catch(error => console.error('Next video playback failed:', error));
      }

      setTimeout(() => {
        setCurrentIndex(newNextIndex);
        setIsTransitioning(false);
        scheduleNextTransition(newNextIndex);
      }, TRANSITION_DURATION);
    } else {
      switchContent();
    }
  }, [currentIndex, isMobile, switchContent]);

  const scheduleNextTransition = useCallback((index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isMobile) {
      timeoutRef.current = setTimeout(startTransition, CONTENT_DURATION);
    } else {
      const videoDuration = videoDurations[index];
      const duration = Math.min(Math.max(videoDuration, MIN_CONTENT_DURATION), MAX_CONTENT_DURATION);
      timeoutRef.current = setTimeout(startTransition, duration - TRANSITION_DURATION);
    }
  }, [videoDurations, startTransition, isMobile]);

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(switchContent, CONTENT_DURATION);
      return () => clearInterval(interval);
    } else {
      videoRefs.current.forEach((ref, index) => {
        const video = ref.current;
        if (video) {
          video.addEventListener('loadedmetadata', () => {
            setVideoDurations(prev => {
              const newDurations = [...prev];
              newDurations[index] = video.duration * 1000;
              return newDurations;
            });
          });

          if (index === currentIndex) {
            video.play().catch(error => console.error('Current video playback failed:', error));
          } else {
            video.pause();
            video.currentTime = 0;
          }
        }
      });
      scheduleNextTransition(currentIndex);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [currentIndex, isMobile, switchContent, scheduleNextTransition]);

  const renderContent = () => {
    if (isMobile) {
      return IMAGES.map((src, index) => {
        const isActive = index === currentIndex;
        const className = `absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
          isActive ? 'opacity-100' : 'opacity-0'
        } ${isActive ? `zoom-out-${index}` : ''}`;

        return (
          <img
            key={src}
            className={className}
            src={src}
            alt={intl.formatMessage({ id: 'hero.image.alt' }, { index: index + 1 })}
          />
        );
      });
    } else {
      return VIDEOS.map((src, index) => {
        const isActive = index === currentIndex;
        const isNext = index === nextIndex;
        return (
          <video
            key={src}
            ref={videoRefs.current[index]}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000`}
            style={{
              opacity: isActive ? (isTransitioning ? 1 - (isNext ? 1 : 0) : 1) :
                       isNext ? (isTransitioning ? 1 : 0) : 0
            }}
            src={src}
            muted
            playsInline
          />
        );
      });
    }
  };

  const getHeroTitle = () => {
    const locale = intl.locale;
    if (locale === 'ja' && isDesktop) {
      return intl.formatMessage({ id: 'hero.title.desktop' });
    }
    return intl.formatMessage({ id: 'hero.title' });
  };

  return (
    <section ref={ref} id="hero-section" className="relative h-screen overflow-hidden bg-gray-900">
      <style jsx>{`
        ${IMAGES.map((_, index) => `
          .zoom-out-${index} {
            animation: zoomOut${index} ${CONTENT_DURATION}ms linear;
            animation-fill-mode: both;
          }
          @keyframes zoomOut${index} {
            0% { transform: scale(1.1); }
            100% { transform: scale(1); }
          }
        `).join('\n')}
      `}</style>
      {renderContent()}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-30" />
      <div className="relative z-40 flex flex-col justify-center items-center h-full text-white px-4 max-w-7xl lg:max-w-[98%] xl:max-w-[92%] 2xl:max-w-[95%] 3xl:max-w-[1500px] mx-auto">
        <h1 className="mb-6 animate-fadeInUp text-center w-full" style={{ animationDelay: '300ms' }}>
          <span className="font-heading text-4xl sm:text-5xl lg:text-[3.25rem] xl:text-6xl 2xl:text-7xl font-bold leading-tight sm:leading-tight lg:leading-tight xl:leading-tight 2xl:leading-tight text-white inline-block max-w-full lg:max-w-[98%] xl:max-w-[100%] 2xl:max-w-[100%]" style={{ 
            textShadow: `
              0 1px 2px rgba(0,0,0,0.1),
              0 2px 4px rgba(0,0,0,0.1),
              0 4px 8px rgba(0,0,0,0.0.5)
            `
          }}>
            {getHeroTitle().split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < getHeroTitle().split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        </h1>
        <p className="font-body text-base sm:text-lg lg:text-2xl xl:text-2xl 2xl:text-3xl text-center animate-fadeInUp leading-relaxed sm:leading-relaxed xl:leading-normal 2xl:leading-normal text-white text-opacity-80 max-w-full lg:max-w-[90%] xl:max-w-[85%] 2xl:max-w-[90%]" style={{ animationDelay: '600ms', textShadow: '0 1px 2px rgba(0,0,0,0.2)' }}>
          {intl.formatMessage({ id: 'hero.description' })}
        </p>
      </div>
    </section>
  );
}