import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from '../components/SectionLayout';

export default function Vision() {
  const intl = useIntl();

  return (
    <SectionLayout id="vision" accent="primary">
      <h2 className="section-title animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'vision.title' })}
      </h2>
      <p className="section-subtitle animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'vision.subtitle' })}
      </p>
      <div className="animate-fadeInUp" style={{animationDelay: '0.9s'}}>
        <h3 className="text-3xl md:text-4xl font-bold text-text-default leading-normal mb-6 font-serif">
          {intl.formatMessage({ id: 'vision.heading' })}
        </h3>
        <p className="text-base sm:text-lg md:text-xl text-text-default md:leading-relaxed leading-relaxed font-body">
          {intl.formatMessage({ id: 'vision.description' })}
        </p>
      </div>
    </SectionLayout>
  );
}