import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from '../components/SectionLayout';

const ValueCard = ({ titleId, descriptionId, index }) => {
  const intl = useIntl();
  return (
    <div className={`mb-12 animate-fadeInUp`} style={{animationDelay: `${index * 0.2}s`}}>
      <h3 className="text-2xl font-bold text-text-default mb-4 font-serif">
        {intl.formatMessage({ id: titleId })}
      </h3>
      <p className="text-text-default leading-relaxed font-body">
        {intl.formatMessage({ id: descriptionId })}
      </p>
    </div>
  );
};

export default function Value() {
  const intl = useIntl();
  const values = [
    { titleId: 'value.card1.title', descriptionId: 'value.card1.description' },
    { titleId: 'value.card2.title', descriptionId: 'value.card2.description' },
    { titleId: 'value.card3.title', descriptionId: 'value.card3.description' },
    { titleId: 'value.card4.title', descriptionId: 'value.card4.description' },
  ];

  return (
    <SectionLayout id="value" accent="secondary">
      <h2 className="section-title font-heading animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'value.title' })}
      </h2>
      <p className="section-subtitle font-body animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'value.subtitle' })}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-16">
        {values.map((value, index) => (
          <ValueCard key={index} {...value} index={index} />
        ))}
      </div>
    </SectionLayout>
  );
}