import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from '../components/SectionLayout';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import Textarea from '../components/ui/Textarea';

export default function Contact() {
  const intl = useIntl();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted');
  };

  const inquiryTypes = [
    'contact.form.inquiryType1',
    'contact.form.inquiryType2',
    'contact.form.inquiryType3',
    'contact.form.inquiryType4'
  ];

  return (
    <SectionLayout id="contact" accent="secondary">
      <h2 className="section-title font-heading animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'contact.title' })}
      </h2>
      <p className="section-subtitle font-body animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'contact.subtitle' })}
      </p>
      <div className="animate-fadeInUp" style={{animationDelay: '0.9s'}}>
        <p className="text-base sm:text-lg md:text-xl text-text-default mb-6 font-body">
          {intl.formatMessage({ id: 'contact.description1' })}
        </p>
        <p className="text-sm sm:text-base md:text-lg text-text-default mb-8 font-body">
          {intl.formatMessage({ id: 'contact.description2' })}
          <a href="http://lin.ee/3ULbL6E" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
            http://lin.ee/3ULbL6E
          </a>
        </p>
        <form className="space-y-6 max-w-3xl" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-text-default mb-1 font-body">
                {intl.formatMessage({ id: 'contact.form.name' })}
              </label>
              <Input type="text" id="name" name="name" placeholder={intl.formatMessage({ id: 'contact.form.name' })} required />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-text-default mb-1 font-body">
                {intl.formatMessage({ id: 'contact.form.email' })}
              </label>
              <Input type="email" id="email" name="email" placeholder={intl.formatMessage({ id: 'contact.form.email' })} required />
            </div>
          </div>
          <div>
            <p className="block text-sm font-medium text-text-default mb-4 font-body">
              {intl.formatMessage({ id: 'contact.form.inquiryType' })}
            </p>
            <div className="space-y-4">
              {inquiryTypes.map((typeId, index) => (
                <label key={index} className="flex items-center">
                  <input 
                    type="radio" 
                    name="inquiry_type" 
                    value={intl.formatMessage({ id: typeId })} 
                    className="form-radio h-5 w-5 text-primary border-gray-300 focus:ring-0 checked:border-transparent" 
                    required 
                  />
                  <span className="ml-2 text-sm text-text-default font-body">
                    {intl.formatMessage({ id: typeId })}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-text-default mb-1 font-body">
              {intl.formatMessage({ id: 'contact.form.message' })}
            </label>
            <Textarea id="message" name="message" rows="5" placeholder={intl.formatMessage({ id: 'contact.form.message' })} required />
          </div>
          <div className="flex justify-center sm:justify-start">
            <Button type="submit" className="px-8 py-2.5 text-lg font-body w-full sm:w-auto min-w-[200px]">
              {intl.formatMessage({ id: 'contact.form.submit' })}
            </Button>
          </div>
        </form>
      </div>
      {/* 下部に余白を追加 */}
      <div className="h-20"></div>
    </SectionLayout>
  );
}