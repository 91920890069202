import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { LanguageContext } from '../../contexts/LanguageContext';
import styles from './Header.module.css';

const Header = () => {
  const [isHeroVisible, setIsHeroVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const intl = useIntl();
  const { locale, switchLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById('hero-section');
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        setIsHeroVisible(rect.bottom > 0);
      }
      if (isInitialLoad) {
        setIsInitialLoad(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isInitialLoad]);

  const easeInOutCubic = t => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

  const scrollToElement = (element, duration) => {
    const start = window.pageYOffset;
    const target = element.getBoundingClientRect().top + window.pageYOffset - 100;
    const distance = target - start;
    let startTime = null;

    const animation = currentTime => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutCubic(Math.min(timeElapsed / duration, 1)) * distance;
      window.scrollTo(0, start + run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      scrollToElement(targetElement, 800);
      setIsMenuOpen(false);
    }
  };

  const headerClass = `${styles.header} ${(!isHeroVisible || isMenuOpen) && !isInitialLoad ? styles.headerScrolled : ''}`;

  const linkStyle = {
    color: isHeroVisible && !isMenuOpen ? 'white' : '#222222',
    transition: 'color 0.3s ease-in-out',
  };

  // ロゴパスの決定: ヒーローセクションの表示状態と現在の言語に基づいて選択
  const logoPath = isHeroVisible && !isMenuOpen
    ? process.env.PUBLIC_URL + `/assets/images/${locale === 'ja' ? 'jp' : 'en'}_jtc_logo_white.svg`
    : process.env.PUBLIC_URL + `/assets/images/${locale === 'ja' ? 'jp' : 'en'}_jtc_logo_normal.svg`;

  const navItems = [
    { id: 'about', title: intl.formatMessage({ id: 'header.about' }) },
    { id: 'service', title: intl.formatMessage({ id: 'header.service' }) },
    { id: 'vision', title: intl.formatMessage({ id: 'header.vision' }) },
    { id: 'value', title: intl.formatMessage({ id: 'header.value' }) },
    { id: 'recruit', title: intl.formatMessage({ id: 'header.recruit' }) },
    { id: 'company', title: intl.formatMessage({ id: 'header.company' }) },
    { id: 'contact', title: intl.formatMessage({ id: 'header.contact' }) },
  ];

  const iconColor = isHeroVisible && !isMenuOpen ? '#ffffff' : '#222222';

  // 言語切り替え関数
  const handleLanguageSwitch = () => {
    switchLanguage(locale === 'ja' ? 'en' : 'ja');
  };

  return (
    <header className={headerClass}>
          <div className={`${styles.container} 2xl:max-w-[95%] 3xl:max-w-[90%] 2xl:px-8 3xl:px-12`}>
          <button onClick={() => window.scrollTo(0, 0)} className={styles.logo}>
          <div className={`${styles.logoWrapper} h-full`}>
            <img
              src={logoPath}
              alt={intl.formatMessage({ id: 'header.logoAlt' })}
              className={`${styles.logoImage} h-full w-auto object-contain object-left`}
            />
          </div>
        </button>
        <nav className={`${styles.nav} 2xl:space-x-4 3xl:space-x-6`}>
          {navItems.map((item) => (
            <a
              key={item.id}
              href={`#${item.id}`}
              className={`${styles.navLink} ${locale === 'ja' ? 'font-body text-ja-sm' : 'font-heading'} 2xl:text-lg 3xl:text-xl`}
              style={linkStyle}
              onClick={(e) => handleNavClick(e, item.id)}
            >
              {item.title}
            </a>
          ))}
          {/* デスクトップ用言語切り替えボタン */}
          <button
            onClick={handleLanguageSwitch}
            className={`${styles.navLink} ${styles.langSwitchDesktop} ${locale === 'ja' ? 'font-body text-ja-sm' : 'font-heading'} 2xl:text-lg 3xl:text-xl flex items-center justify-center`}
            style={linkStyle}
          >
            <svg className={styles.globeIcon} fill={iconColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
            </svg>
            <span>{locale === 'ja' ? 'English' : '日本語'}</span>
          </button>
        </nav>
        <div className={styles.mobileButtons}>
          {/* モバイル用言語切り替えボタン */}
          <button
            onClick={handleLanguageSwitch}
            className={`${styles.mobileButton} ${isHeroVisible && !isMenuOpen ? styles.mobileButtonLight : ''}`}
            aria-label="Switch language"
          >
            <svg className={styles.mobileGlobeIcon} fill={iconColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
            </svg>
            <span className="text-xs mt-1">{locale === 'ja' ? 'English' : '日本語'}</span>
          </button>
          {/* モバイル用メニューボタン */}
          <button
            className={`${styles.mobileButton} ${isHeroVisible && !isMenuOpen ? styles.mobileButtonLight : ''}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
                <span className="text-xs mt-1">CLOSE</span>
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="3" y1="12" x2="21" y2="12"></line>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
                <span className="text-xs mt-1">MENU</span>
              </>
            )}
          </button>
        </div>
      </div>
      {/* モバイルメニュー */}
      <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.mobileMenuOpen : ''}`}>
        {navItems.map((item) => (
          <a
            key={item.id}
            href={`#${item.id}`}
            className={`${styles.mobileNavLink} ${locale === 'ja' ? 'font-body text-ja-sm' : 'font-heading'}`}
            onClick={(e) => handleNavClick(e, item.id)}
          >
            <span className={styles.mobileNavTitle}>{item.title}</span>
          </a>
        ))}
      </div>
    </header>
  );
};

export default Header;