import React, { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../locales/en.json';
import Japanese from '../locales/ja.json';

export const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(() => {
    // ローカルストレージから言語設定を読み込む
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      return savedLanguage;
    }
    
    // ブラウザの言語設定を検出
    const browserLang = navigator.language.split('-')[0];
    return browserLang === 'ja' ? 'ja' : 'en'; // 日本語以外はデフォルトで英語
  });

  // locale に基づいてメッセージを設定
  const [messages, setMessages] = useState(() => {
    return locale === 'en' ? English : Japanese;
  });

  // locale が変更されたときにローカルストレージを更新
  useEffect(() => {
    localStorage.setItem('language', locale);
  }, [locale]);

  // 言語を切り替える関数
  const switchLanguage = (lang) => {
    setLocale(lang);
    setMessages(lang === 'en' ? English : Japanese);
  };

  return (
    <LanguageContext.Provider value={{ locale, switchLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;