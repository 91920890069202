import React from 'react';
import { useInView } from 'react-intersection-observer';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Hero from './sections/Hero';
import About from './sections/About';
import Service from './sections/Service';
import Vision from './sections/Vision';
import Value from './sections/Value';
import Recruit from './sections/Recruit';
import Company from './sections/Company';
import Contact from './sections/Contact';
import LanguageProvider from './contexts/LanguageContext';

function FadeInSection({ children, id }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: '0px 0px -10% 0px'
  });

  return (
    <section ref={ref} id={id} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
      {children}
    </section>
  );
}

function App() {
  return (
    <LanguageProvider>
      <div className="min-h-screen flex flex-col font-body">
        <Header />
        <main className="flex-grow">
          <Hero id="hero-section" />
          <FadeInSection id="about"><About /></FadeInSection>
          <FadeInSection id="service"><Service /></FadeInSection>
          <FadeInSection id="vision"><Vision /></FadeInSection>
          <FadeInSection id="value"><Value /></FadeInSection>
          <FadeInSection id="recruit"><Recruit /></FadeInSection>
          <FadeInSection id="company"><Company /></FadeInSection>
          <FadeInSection id="contact"><Contact /></FadeInSection>
        </main>
        <Footer />
      </div>
    </LanguageProvider>
  );
}

export default App;