import React from 'react';

const Button = ({ 
  children, 
  variant = 'default', 
  size = 'md',
  className = '',
  icon: Icon,
  href,
  ...props 
}) => {
  const baseStyles = "inline-flex items-center justify-center rounded-full font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none";
  
  const variants = {
    default: "bg-primary text-primary-foreground hover:bg-primary/90",
    outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
    secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
    ghost: "hover:bg-accent hover:text-accent-foreground",
    link: "underline-offset-4 hover:underline text-primary",
    xlink: "bg-text-default text-white hover:bg-gray-800",
  };

  const sizes = {
    sm: "h-10 px-3 rounded-md text-sm",
    md: "h-12 py-2 px-4",
    lg: "h-13 px-8 rounded-md",
  };

  const classes = [
    baseStyles,
    variants[variant],
    sizes[size],
    className // ここでclassNameを追加
  ].filter(Boolean).join(' ');

  const ButtonOrLink = href ? 'a' : 'button';

  return (
    <ButtonOrLink
      className={classes}
      href={href}
      target={href ? "_blank" : undefined}
      rel={href ? "noopener noreferrer" : undefined}
      {...props}
    >
      {Icon && <Icon className="w-5 h-5 mr-2" />}
      {children}
    </ButtonOrLink>
  );
};

export default Button;