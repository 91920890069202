import React, { useState, useEffect } from 'react';

const Footer = () => {
  const [textColor, setTextColor] = useState('text-white');

  useEffect(() => {
    const handleScroll = () => {
      // window.scrollY が 0 より大きければ、スクロールされたとみなす
      if (window.scrollY > 0) {
        setTextColor('text-text-default');
      } else {
        setTextColor('text-white');
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <footer className="fixed bottom-0 left-0 right-0 py-4 z-50">
      <div className="container mx-auto px-4 text-center">
        <p className={`text-sm font-montserrat transition-colors duration-300 ${textColor}`}>
          © {new Date().getFullYear()} JAPAN TRADING CARD CENTER Co., Ltd.
        </p>
      </div>
    </footer>
  );
};

export default Footer;