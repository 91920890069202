import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from '../components/SectionLayout';

export default function Company() {
  const intl = useIntl();

  // 会社情報の配列
  const companyDetails = [
    { labelId: 'company.name', valueId: 'company.name.value' },
    { labelId: 'company.representative', valueId: 'company.representative.value' },
    { labelId: 'company.location', valueId: 'company.location.value' },
    { labelId: 'company.established', valueId: 'company.established.value' },
    { labelId: 'company.capital', valueId: 'company.capital.value' },
  ];

  // Google Mapsの埋め込みURL
  const mapEmbedUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.43426688545!2d139.77850227733498!3d35.6909296725842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188952dd71f953%3A0x40775284a68a801f!2z44CSMTAzLTAwMTEg5p2x5Lqs6YO95Lit5aSu5Yy65pel5pys5qmL5aSn5Lyd6aas55S677yR77yT4oiS77yXIOaXpeacrOapi-Wkp-WvjOODk-ODqw!5e0!3m2!1sja!2sjp!4v1727424651417!5m2!1sja!2sjp";

  return (
    <SectionLayout id="company" accent="primary">
      {/* セクションタイトル */}
      <h2 className="section-title font-heading animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'company.title' })}
      </h2>
      {/* セクションサブタイトル */}
      <p className="section-subtitle font-body animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'company.subtitle' })}
      </p>
      {/* 会社情報とマップのコンテナ */}
      <div className="flex flex-wrap gap-10 animate-fadeInUp" style={{animationDelay: '0.9s'}}>
        {/* 会社情報リスト */}
        <div className="flex-1 min-w-[300px] flex flex-col justify-between">
          <ul className="space-y-4">
            {companyDetails.map((detail, index) => (
              <li key={index} className="pb-4 border-b border-gray-200 last:border-b-0 last:pb-0">
                <strong className="block text-lg font-semibold text-text-default mb-1 font-serif">
                  {intl.formatMessage({ id: detail.labelId })}
                </strong>
                <span className="text-text-default font-body">
                  {intl.formatMessage({ id: detail.valueId })}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {/* Google Mapsの埋め込み */}
        <div className="flex-1 min-w-[300px]">
          <div className="aspect-w-16 aspect-h-9 h-[450px]">
            <iframe
              src={mapEmbedUrl}
              className="w-full h-full border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title={intl.formatMessage({ id: 'company.map.title' })}
            ></iframe>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
}