import React from 'react';
import { useInView } from 'react-intersection-observer';

const SectionLayout = ({ id, children, accent = 'primary' }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section 
      id={id} 
      ref={ref} 
      className={`py-24 relative overflow-hidden transition-opacity duration-1000 ${inView ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className={`absolute left-0 top-0 bottom-0 w-1 bg-${accent}-100`}></div>
      <div className="container mx-auto px-4 max-w-6xl">
        {React.Children.map(children, (child, index) => 
          React.cloneElement(child, { 
            className: `${child.props.className || ''} animate-fadeInUp`,
            style: { 
              ...child.props.style, 
              animationDelay: `${0.3 * (index + 1)}s` 
            }
          })
        )}
      </div>
    </section>
  );
};

export default SectionLayout;