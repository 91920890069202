import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from '../components/SectionLayout';

export default function About() {
  const intl = useIntl();

  return (
    <SectionLayout id="about" accent="primary">
      <h2 className="section-title animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'about.title' })}
      </h2>
      <p className="section-subtitle animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'about.subtitle' })}
      </p>
      <div className="animate-fadeInUp" style={{animationDelay: '0.9s'}}>
        <h3 className="text-3xl lg:text-4xl font-bold text-text-default mb-6 font-serif">
          <span className="hidden lg:inline-block whitespace-pre-line leading-relaxed">
            {intl.formatMessage({ id: 'about.description.desktop' })}
          </span>
          <span className="lg:hidden leading-normal">
            {intl.formatMessage({ id: 'about.description.mobile' })}
          </span>
        </h3>
        <p className="text-base sm:text-lg md:text-xl text-text-default font-body leading-relaxed md:leading-relaxed">
          {intl.formatMessage({ id: 'about.content' })}
        </p>
      </div>
    </SectionLayout>
  );
}