import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { LanguageContext } from '../contexts/LanguageContext';
import SectionLayout from '../components/SectionLayout';
import Button from '../components/ui/Button';

export default function Recruit() {
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  // 英語表示の場合はnullを返して、このセクションを表示しない
  if (locale === 'en') {
    return null;
  }

  return (
    <SectionLayout id="recruit" accent="secondary">
      <h2 className="section-title font-heading animate-fadeInUp" style={{animationDelay: '0.3s'}}>
        {intl.formatMessage({ id: 'recruit.title' })}
      </h2>
      <p className="section-subtitle font-body animate-fadeInUp" style={{animationDelay: '0.6s'}}>
        {intl.formatMessage({ id: 'recruit.subtitle' })}
      </p>
      <div className="animate-fadeInUp" style={{animationDelay: '0.9s'}}>
        <p className="text-base sm:text-lg md:text-xl text-text-default mb-10 font-body leading-relaxed md:leading-relaxed xl:leading-relaxed">
          {intl.formatMessage({ id: 'recruit.description' })}
        </p>
        <div className="flex justify-center sm:justify-start">
          <Button 
            href="https://hrmos.co/pages/jtc-center" 
            className="text-lg px-8 py-2.5 font-body w-full sm:w-auto min-w-[200px]"
            target="_blank" 
            rel="noopener noreferrer"
          >
            {intl.formatMessage({ id: 'recruit.button' })}
          </Button>
        </div>
      </div>
    </SectionLayout>
  );
}